button.react-datepicker__navigation.react-datepicker__navigation--next, button.react-datepicker__navigation.react-datepicker__navigation--previous{
    width: 20px !important;
    height: 20px !important;
    min-height: fit-content;
    min-width: fit-content;
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 7.5px;
}

button.react-datepicker__navigation.react-datepicker__navigation--next {
    background-image: url("../public/static/images/right-arrow.svg");
    background-size: cover;
}

button.react-datepicker__navigation.react-datepicker__navigation--previous {
    background-image: url("../public/static/images/left-arrow.svg");
    background-size: cover;
}
@mixin fontDmSans(
  $size: 14px,
  $color: $font-color,
  $weight: $fw-regular,
  $height: 22px,
  $letterSpace: 1px
) {
  font-family: 'DM Sans', sans-serif !important;
  font-size: $size !important;
  color: $color;
  font-style: normal !important;
  font-weight: $weight !important;
  line-height: $height;
  letter-spacing: $letterSpace !important;
}

@mixin fonts($size: 14px) {
  font-size: $size !important;
}
@mixin heights($size: 120%) {
  line-height: $size !important;
}

/* ------------------------------------------ headings ------------------------------------------ */
.custom-heading-font-1,
h1.custom-heading-font-1 {
  @include fontDmSans(56px, #0a0b09, 700, 120%, -3px);
  @media all and (max-width: 991px) {
    @include fonts(40px);
  }
}

.custom-heading-font-2,
h2.custom-heading-font-2 {
  @include fontDmSans(48px, #0a0b09, 700, 120%, -3px);
  @media all and (max-width: 991px) {
    @include fonts(36px);
  }
}
.custom-heading-font-3,
h3.custom-heading-font-3 {
  @include fontDmSans(40px, #0a0b09, 700, 120%, -2px);
  @media all and (max-width: 991px) {
    @include fonts(32px);
  }
}
.custom-heading-font-4,
h4.custom-heading-font-4 {
  @include fontDmSans(32px, #0a0b09, 700, 130%, -1px);
  @media all and (max-width: 991px) {
    @include fonts(24px);
    @include heights(140%);
  }
}
.custom-heading-font-5,
h4.custom-heading-font-5 {
  @include fontDmSans(24px, #0a0b09, 700, 120%, -0.5px);
  @media all and (max-width: 991px) {
    @include fonts(20px);
  }
}
.custom-heading-font-6,
h4.custom-heading-font-6 {
  @include fontDmSans(20px, #0a0b09, 700, 120%, 0px);
  @media all and (max-width: 991px) {
    @include fonts(18px);
  }
}

/* ------------------------------------- 28px custom texts ------------------------------------ */
.custom-font-text-larger-bold {
  @include fontDmSans(28px, #0a0b09, 700, 150%, 0px);
}
.custom-font-text-larger-medium {
  @include fontDmSans(28px, #0a0b09, 500, 150%, 0px);
}
.custom-font-text-larger-normal {
  @include fontDmSans(28px, #0a0b09, 400, 150%, 0px);
}

/* ------------------------------------- 20px custom texts ------------------------------------ */
.custom-font-text-large-bold {
  @include fontDmSans(20px, #0a0b09, 700, 150%, 0px);
}
.custom-font-text-large-medium {
  @include fontDmSans(20px, #0a0b09, 500, 150%, 0px);
}
.custom-font-text-large-normal {
  @include fontDmSans(20px, #0a0b09, 400, 150%, 0px);
}

/* ------------------------------------- 18px custom texts ------------------------------------ */
.custom-font-text-medium-bold {
  @include fontDmSans(18px, #0a0b09, 700, 150%, 0px);
}
.custom-font-text-medium-medium {
  @include fontDmSans(18px, #0a0b09, 500, 150%, 0px);
}
.custom-font-text-medium-normal {
  @include fontDmSans(18px, #0a0b09, 400, 150%, 0px);
}

/* ------------------------------------- 16px custom texts ------------------------------------ */
.custom-font-text-regular-bold {
  @include fontDmSans(16px, #0a0b09, 700, 150%, 0px);
}
.custom-font-text-regular-medium {
  @include fontDmSans(16px, #0a0b09, 500, 150%, 0px);
}
.custom-font-text-regular-normal {
  @include fontDmSans(16px, #0a0b09, 400, 150%, 0px);
}

/* ------------------------------------- 14px custom texts ------------------------------------ */
.custom-font-text-small-bold {
  @include fontDmSans(14px, #0a0b09, 700, 150%, 0px);
}
.custom-font-text-small-medium {
  @include fontDmSans(14px, #0a0b09, 500, 150%, 0px);
}
.custom-font-text-small-normal {
  @include fontDmSans(14px, #0a0b09, 400, 150%, 0px);
}

/* ------------------------------------- 12px custom texts ------------------------------------ */
.custom-font-text-tiny-bold {
  @include fontDmSans(12px, #0a0b09, 700, 150%, 0px);
}
.custom-font-text-tiny-medium {
  @include fontDmSans(12px, #0a0b09, 500, 150%, 0px);
}
.custom-font-text-tiny-normal {
  @include fontDmSans(12px, #0a0b09, 400, 150%, 0px);
}

/* ----------------------------------- custom gradient colors ----------------------------------- */
.custom-gradient-color {
  background: linear-gradient(315.97deg, #1159f1 1.64%, #f41657 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* GLOBAL SETTINGS
============================= */
/* Body background */
$body-color: #fff;
$body-bg: #fff;

/* Layout related
============================= */
$container-max-widths: (
  xs: 100%,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px // Custom xxl size
);

/* Typography
----------------------------- */
$font-family-sans-serif: 'DM Sans', sans-serif;
$font-family-monospace: 'DM Sans', sans-serif;

/* Colors
----------------------------- */
$primary: #f71454;
$headings-color: black;

/* Buttons
----------------------------- */
/* Forms + Buttons */
$input-btn-padding-y-lg: 0.8rem;
$input-btn-padding-x-lg: 2rem;

/* Button border radius */
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;